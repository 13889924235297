import axios from 'axios'
import config from '@/libs/config'
import {createHttp} from './request.js'

const userAxios = axios.create({
    baseURL: config.assetsApi,
    timeout: 6000,
})

const $http = createHttp(userAxios)
const post = $http.post

export const uploadAvatar = (params) => {
    return post('/avatar/up',params,true)
}