<template>
	<b-card>
		<!-- Media -->
		<b-media class="mb-2">
			<template #aside>
				<b-avatar
					ref="previewEl"
					:src="userData.avatar"
					:text="avatarText(userData.nickname)"
					:variant="`light-primary`"
					size="90px"
					rounded
				/>
			</template>
			<h4 class="mb-1">
				{{ userData.nickname }}
			</h4>
			<div class="d-flex flex-wrap">
				<b-button
					variant="primary"
					@click="$refs.refInputEl.click()"
				>
					<input
						ref="refInputEl"
						type="file"
						class="d-none"
						accept=".png,.jpg"
						@input="avatarChange"
					>
					<span class="d-none d-sm-inline">{{$t('Upload Avatar')}}</span>
					<feather-icon
						icon="EditIcon"
						class="d-inline d-sm-none"
					/>
				</b-button>
			</div>
		</b-media>
		<div class="d-flex mt-2 mb-1">
			<feather-icon
				icon="UserIcon"
				size="19"
			/>
			<h4 class="mb-0 ml-50">{{$t('Base Info')}}</h4>
		</div>
		<!-- User Info: Input Fields -->
		<b-form>
			<b-row>

				<!-- Field: Username -->
				<b-col cols="12" md="4">
					<b-form-group :label="$t('Nickname')" label-for="nickname">
						<b-form-input id="nickname" v-model="userData.nickname" />
					</b-form-group>
				</b-col>

				<!-- Field: Role -->
				<!-- <b-col cols="12" md="4">
					<b-form-group :label="$t('User Role')" label-for="user-role">
						<v-select
							v-model="userData.role"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="roleOptions"
							:reduce="val => val.value"
							:clearable="false"
							input-id="user-role"
						/>
					</b-form-group>
				</b-col> -->

				<!-- Field: Gender -->
				<b-col cols="12" md="6" lg="4">
					<b-form-group :label="$t('Gender')" label-for="sex">
						<v-select
							v-model="userData.sex"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="genderOptions"
							:reduce="val => val.value"
							:clearable="false"
							input-id="sex"
						/>
					</b-form-group>
				</b-col>

				<!-- Field: Mobile -->
				<b-col cols="12" md="6" lg="4">
					<b-form-group
						:label="$t('Mobile')"
						label-for="mobile"
						label-class="mb-1"
					>
						<p class="mt-1">{{userData.tel}}</p>
					</b-form-group>
				</b-col>

				<!-- Field: Email -->
				<b-col cols="12" md="4">
					<b-form-group :label="$t('Email')" label-for="email" label-class="mb-1">
						<span class="text-primary" v-if="!userData.email">{{$t('Unbounded')}}</span>
						<span class="text-primary" v-else>{{userData.email}}</span>
					</b-form-group>
				</b-col>

				<!-- Field: 微信 -->
				<b-col cols="12" md="4">
					<b-form-group
						:label="$t('WeChat')"
						label-for="email"
						label-class="mb-1"
					>
						<span class="text-primary" v-if="userData.wechat==='0'">{{$t('Unbounded')}}</span>
						<span class="text-primary" v-else>{{userData.wechatname}}</span>
					</b-form-group>
				</b-col>
				<!-- Field: Facebook
				<b-col cols="12" md="4">
					<b-form-group
						label="Facebook"
						label-for="Facebook"
						label-class="mb-1"
					>
						<span class="text-primary">{{$t('Unbounded')}}</span>
					</b-form-group>
				</b-col> -->
				<!-- Field: Facebook
				<b-col cols="12" md="4">
					<b-form-group
						label="Twitter"
						label-for="Twitter"
						label-class="mb-1"
					>
						<span class="text-primary">{{$t('Unbounded')}}</span>
					</b-form-group>
				</b-col> -->
			</b-row>
		</b-form>

		<!-- Action Buttons -->
		<b-button
			variant="primary"
			class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
			:block="$store.getters['app/currentBreakPoint'] === 'xs'"
			@click="saveAction"
		>
			{{$t('Save')}}
		</b-button>
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>设置用户基本信息</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>修改头像</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>绑定微信、Twitter、FaceBook</span>
				</li>
			</ul>
		</Function>
	</b-card>
</template>

<script>
import {
	BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormRadioGroup
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {getUserInfo, setUserInfo} from "@/libs/apis/userApi"
import {uploadAvatar} from "@/libs/apis/assetsApi"
import config from "@/libs/config"


export default {
	components: {
		BButton,
		BMedia,
		BAvatar,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BForm,
		BTable,
		BCard,
		BCardHeader,
		BCardTitle,
		BFormCheckbox,
		BFormRadioGroup,
		vSelect,
	},
	data () {
		return {
			genderOptions: [
				{ label: this.$t('Unknown'), value: -1 },
				{ label: this.$t('Male'), value: 1 },
				{ label: this.$t('Female'), value: 0 },
			],
			roleOptions: [
				{ label: this.$t('Admin'), value: 'admin' },
				{ label: this.$t('Editor'), value: 'editor' }
			],
			userData: {},
		}
	},
	created () {
		this.getData()
	},
	methods: {
		avatarText,
		getData () {
			getUserInfo().then(res=>{
				this.userData = res.row
			},err=>{
				console.log(err)
				this.$toast.error(err.message)
			})
		}, 
		saveAction () {
			let params = {}
			if(!this.userData.nickname){
				this.$toast.error('昵称不能为空')
				return
			}
			params.nickname = this.userData.nickname
			params.sex = this.userData.sex
			if(this.userData.avatar){
				params.avatar = this.userData.avatar
			}
			
			setUserInfo(params).then(res=>{
				this.$toast.success('保存成功')
			},err=>{
				this.$toast.error(err.message)
			})
		},
		avatarChange (e) {
			let file = e.target.files[0]
			let formData = new FormData()
			formData.append('file',file)
			uploadAvatar(formData).then(res=>{
				this.userData.avatar = config.assetsUrl + res.file
			},err=>{
				this.$toast(err.mesage)
			})
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
